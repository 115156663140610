import React from 'react';
import { HashRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import config from './config';
import ContextsProvider from './contexts/contextsProvider';
import Login from './features/Login';
import ForgotPassword from './features/Login/ForgotPassword';
import ResetPassword from './features/Login/ResetPassword';
import { VogTrainings } from './features/Login/Trainings';
import Tyra from './features/Tyra';
import VERTICALS from './models/Verticals';
import { getCookie } from './utils';
import CXPSetPassword from './features/CXP/CXPSetPassword';
import CXPAlreadyUpgraded from './features/CXP/CXPAlreadyUpgraded';

const ProtectedRoutes = () => {
  const cookie = getCookie('sso_token');
  return cookie ? (
    <Outlet />
  ) : (
    <Navigate to={`/auth/${config.vertical}/login`} state={{ previousHash: window.location.hash }} replace />
  );
};

const tagManagerArgs = {
  gtmId: config.gtm.containerId,
  auth: config.gtm.auth,
  preview: config.gtm.preview,
};

TagManager.initialize(tagManagerArgs);

const App = () => (
  <ContextsProvider>
    <HashRouter>
      <Routes>
        <Route path='auth/*'>
          <Route path={VERTICALS.HOTELS}>
            <Route path='login/:themeId?' element={<Login vertical={VERTICALS.HOTELS} />} />
            <Route path='forgot_password/:themeId?' element={<ForgotPassword vertical={VERTICALS.HOTELS} />} />
            <Route path='reset_password/:themeId?' element={<ResetPassword vertical={VERTICALS.HOTELS} />} />
          </Route>
          <Route path={VERTICALS.RESTAURANTS}>
            <Route path='login/:themeId?' element={<Login vertical={VERTICALS.RESTAURANTS} />} />
            <Route path='forgot_password/:themeId?' element={<ForgotPassword vertical={VERTICALS.RESTAURANTS} />} />
            <Route path='reset_password/:themeId?' element={<ResetPassword vertical={VERTICALS.RESTAURANTS} />} />
          </Route>
          <Route path='*' element={<Navigate to={`/auth/${config.vertical}/login`} replace />} />
        </Route>
        <Route path='trainings/*'>
          <Route path='vog' element={<VogTrainings />} />
        </Route>
        <Route path='cxp/*'>
          <Route path='cxp_onboarding' element={<CXPSetPassword />} />
          <Route path='cxp_already_upgraded' element={<CXPAlreadyUpgraded />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path='*' element={<Tyra />} />
        </Route>
      </Routes>
    </HashRouter>
  </ContextsProvider>
);

export default App;
